import { Store } from 'vuex';
import { ISearchResultPropertiesBackend } from './searchResultPropertiesBackend.interface';
import { BaseFilters, IAvailableFilters, ICurrentFilters } from './searchResultPropertiesState.interface';
import { CancelTokenSource } from 'axios';

export class SearchResultPropertiesModel<S> {
    protected _modelName: string;
    protected _store: Store<S>;

    public constructor(store: Store<S>) {
        this._store = store;
        this._modelName = 'searchResultProperties';
    }

    public get totalProducts(): number {
        return this._store.getters[`${this._modelName}/getTotalProducts`];
    }

    public get activeRequest(): CancelTokenSource {
        return this._store.getters[`${this._modelName}/getActiveRequest`];
    }

    public get currentPageNumber(): number {
        return this._store.getters[`${this._modelName}/getCurrentPageNumber`];
    }

    public get currentFilters(): ICurrentFilters {
        return this._store.getters[`${this._modelName}/currentFilters`];
    }

    public get lastPageNumber(): number {
        return this._store.getters[`${this._modelName}/getLastPageNumber`];
    }

    public get availableFilters(): IAvailableFilters {
        return this._store.getters[`${this._modelName}/getAvailableFilters`];
    }

    public getProductIdsByPage(page: number) {
        return this._store.getters[`${this._modelName}/getProductIdsByPage`](page);
    }

    public setCurrentPage(page: number) {
        return this._store.dispatch(`${this._modelName}/setCurrentPage`, page);
    }

    public updateState(properties: ISearchResultPropertiesBackend): Promise<void> {
        return this._store.dispatch(`${this._modelName}/update`, properties);
    }

    public search(params: any): Promise<void> {
        return this._store.dispatch(`${this._modelName}/search`, params);
    }

    public toggleBaseFilter(payload: { id: number; baseFilter: BaseFilters }) {
        return this._store.dispatch(`${this._modelName}/toggleBaseFilter`, payload);
    }

    public baseFilterIsSet(payload: { id: number; baseFilter: BaseFilters }) {
        return this._store.getters[`${this._modelName}/baseFilterIsSet`](payload);
    }

    public createHash(result: ISearchResultPropertiesBackend) {
        let string: string = '';
    }
}
