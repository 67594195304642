



















import { Component } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VueCartPreview extends BaseComponent {
    public get cartPositions() {
        return this.models.cart.cartItems;
    }
    public sumCart() {
        return this.cartPositions.reduce((prev, cur) => prev + cur.net_price * cur.quantity, 0);
    }
}
