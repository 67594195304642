
















import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';
import VueCartPosition from './vueCartPosition.vue';
import { ICartItem } from '../../store/cart/cartItem.interface';

@Component({
    components: { VueCartPosition },
})
export default class VueCartPositions extends BaseComponent {
    public get cartPositions() {
        return this.models.cart.cartItems;
    }

    public sumItemPrices() {
        return this.cartPositions.reduce((prev, cur) => prev + cur.net_price * cur.quantity, 0);
    }
}
