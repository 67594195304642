import { BaseFilters, ISearchResultPropertiesState } from './searchResultPropertiesState.interface';
import { SearchResultPropertiesRequestModel } from './searchResultProperties.requestModel';

export const requestModel = (): typeof SearchResultPropertiesRequestModel => {
    return SearchResultPropertiesRequestModel;
};

export const getProductIdsByPage =
    (state: ISearchResultPropertiesState) =>
    (page: number): number[] => {
        return state.productIds[page] || [];
    };

export const baseFilterIsSet =
    (state: ISearchResultPropertiesState) =>
    (payload: { id: number; baseFilter: BaseFilters }): boolean => {
        return state.currentFilters[payload.baseFilter].includes(payload.id);
    };

export const getTotalProducts = (state: ISearchResultPropertiesState): number => {
    return state.totalProducts || 0;
};

export const getCategoryId = (state: ISearchResultPropertiesState): number => {
    return state.categoryId;
};

export const getIsNew = (state: ISearchResultPropertiesState): boolean => {
    return state.isNew;
};

export const currentFilters = (state: ISearchResultPropertiesState): any => {
    return state.currentFilters;
};

export const getAvailableFilters = (state: ISearchResultPropertiesState): any => {
    return state.availableFilters;
};

export const getCurrentPageNumber = (state: ISearchResultPropertiesState): number => {
    return state.currentPage || 1;
};

export const getLastPageNumber = (state: ISearchResultPropertiesState): number => {
    return state.lastPage || 1;
};
