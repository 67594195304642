



























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base/baseComponent';

@Component
export default class VueCategoryList extends BaseComponent {
    @Prop({
        type: Number,
    })
    public selectedCategoryId: number;

    public get selectedCategory() {
        if (!this.selectedCategoryId) {
            return null;
        }

        return this.models.category.getById(this.selectedCategoryId);
    }

    public get childCategories() {
        if (!this.selectedCategoryId) {
            return null;
        }

        return this.models.category.getChildren(this.selectedCategoryId);
    }

    public get parentCategory() {
        if (!this.selectedCategory) {
            return null;
        }

        return this.models.category.getById(this.selectedCategory.parent_id);
    }
}
